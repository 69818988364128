import styled from 'styled-components';
import React from 'react';
import { Cartridge } from 'src/components/molecule';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import tokens from '@veneer/tokens';
import { EmptyContainer } from 'src/components/atom';
import { t } from 'src/utils/commonMethods';

type TSuppliesNonLFProps = {
  supplies: any;
};

const SuppliesNonLF = (props: TSuppliesNonLFProps) => {
  const { detailsRnPmState } = useStoreState();
  const { suppliesReadRnPm } = detailsRnPmState;
  const { isWex, mode } = useShellRootContext();
  const hasInk = props.supplies?.ink?.length !== 0;

  console.log('supplies non lif');

  return (
    <>
      {suppliesReadRnPm && (
        <>
          <BorderSeparator isWex={isWex} mode={mode} />
          <TabWrapper data-testid="supplies-tab-for-common">
            {hasInk ? (
              <Cartridge minHeight={300} />
            ) : (
              <EmptyContainer message={t('message.no_supplies')} id="empty-supplies" />
            )}
          </TabWrapper>
        </>
      )}
    </>
  );
};

export default SuppliesNonLF;

const TabWrapper = styled.div`
  width: 100%;

  #empty-supplies {
    height: 100%;
    margin-top: 0;
  }
`;

const BorderSeparator = styled.div`
  display: inline-block;
  ${(props) => {
    if (props.isWex && props.mode == 'dark') return `border-left: 2px solid ${tokens.color.gray8};`;
    else return 'border-left: 2px solid rgba(33, 33, 33, 0.1);';
  }}
  margin-right: 32px;
`;
