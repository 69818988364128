import styled, { css } from 'styled-components';
import tokens from '@veneer/tokens';
import React from 'react';
import { t, toTitleCase } from 'src/utils/commonMethods';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { Button, IconCheckmarkCircle, IconXCircle } from '@veneer/core';
import { semantics } from '@veneer/semantics';

type TDeviceConnectivityProps = {
  connectionState: string;
};

const DeviceConnectivity = (props: TDeviceConnectivityProps) => {
  const { isWex, mode } = useShellRootContext();
  const wexIcon = {
    online: {
      leadingIcon: <IconCheckmarkCircle filled={true} customStyle={{ width: '14px !important' }} />,
      colorScheme: 'positive',
      width: '78px',
    },
    offline: {
      leadingIcon: <IconXCircle filled={true} customStyle={{ width: '14px !important' }} />,
      colorScheme: 'negative',
      width: '81px',
    },
  };

  const Icon = {
    wex: (
      <Button
        colorScheme={wexIcon[props.connectionState].colorScheme}
        leadingIcon={wexIcon[props.connectionState].leadingIcon}
        customStyle={{
          minWidth: wexIcon[props.connectionState].width,
          width: wexIcon[props.connectionState].width,
          height: '24px',
          maxWidth: '256px',
          borderRadius: '4px',
          gap: '4px',
          padding: '4px 12px 4px 8px',
        }}
      >
        <WXText>
          <span>{toTitleCase(props.connectionState)}</span>
        </WXText>
      </Button>
    ),
    ecp: (
      <ECPIconWrapper>
        <CustomIcon
          data-testid={'device-connectivity-icon'}
          connectionState={props.connectionState}
        />
        <ConnectivityDetail>{t(`info.${props.connectionState}`)}</ConnectivityDetail>
        <Value isWex={isWex} mode={mode}></Value>
      </ECPIconWrapper>
    ),
  };

  return (
    <Wrapper data-testid={'device-connectivity'}>
      <BorderSeparator mode={mode} />

      <Content>
        <Title data-testid={'connectivity-text'}>
          <Text>{t('info.connectivity')}</Text>
        </Title>
        {Icon[isWex ? 'wex' : 'ecp']}
      </Content>
    </Wrapper>
  );
};

export default DeviceConnectivity;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px 10px 0;
  min-width: 90px;
`;

const BorderSeparator = styled.div`
  display: inline-block;
  border-left: 2px solid ${({ mode }) => semantics.color[mode].stroke.default};
  height: 32px;
  margin-right: 10px;
`;

const Content = styled.div``;

const Title = styled.div`
  line-height: 16px;
  font-size: 14px;
  color: var(--gray500);
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Value = styled.div`
  padding-top: 3px;
  display: flex;
  align-items: center;
  ${(props) => {
    if (props.isWex && props.mode == 'dark') return `color: ${tokens.color.white};`;
    else return 'color: #333333;';
  }}
  font-weight: bold;
  font-size: 16px;
`;

const Text = styled.span`
  margin-right: 4px;
`;

const ECPIconWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const IconBackground = css`
  ${({ connectionState }) => {
    switch (connectionState) {
      case 'online':
        return tokens.color.green6;
      case 'offline':
        return tokens.color.red6;
    }
  }}
`;

const CustomIcon = styled.div`
  width: 12px;
  height: 12px;
  margin-right: 8px;
  display: inline-block;
  border-radius: 50%;
  background-color: ${IconBackground};
`;

const ConnectivityDetail = styled.div`
  line-height: 24px;
`;

const WXText = styled.div`
  span {
    font-family: Forma DJR UI !important;
    font-size: 12px !important;
    font-weight: 500 !important;
    line-height: 16px !important;
    text-align: center !important;
  }
`;
