import { ThemeProvider } from '@veneer/core';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import React, { useEffect, useState } from 'react';
import { getSystem } from 'src/styles/palette';

const CustomThemeProvider = ({ children }) => {
  const { isWex, theme, mode } = useShellRootContext();
  const [themeProviderProps, setThemeProviderProps] = useState({
    ...theme?.getThemeProviderProperties?.(),
    density: 'high',
    shape: 'round',
    mode: theme?.getUserThemeMode?.(),
  });

  useEffect(() => {
    if (isWex) {
      setThemeProviderProps({
        ...themeProviderProps,
        density: 'high',
        shape: 'round',
        mode: mode,
        customSemantics: getSystem(),
      });
    }
  }, [mode]);
  return <ThemeProvider {...themeProviderProps}>{children}</ThemeProvider>;
};

export default CustomThemeProvider;
