import styled from 'styled-components';
import React from 'react';
import tokens from '@veneer/tokens';
import { fetchImage } from 'src/utils/commonMethods';
import { IconPrinter } from '@veneer/core';
import { useStoreState } from 'src/store/useStoreState';
import { useShellRootContext } from 'src/contexts/ShellRoot';
import { DeviceStatusMessage } from 'src/components/molecule';
import {
  DeviceStatus,
  DeviceAssessment,
  DeviceGroupStatus,
  DeviceConnectivity,
  DeviceStatusUpdated,
} from 'src/components/atom';

type TDeviceInfoProps = {
  isPolicyTab: boolean;
};

export const DeviceInfo = (props: TDeviceInfoProps) => {
  const { ecpDeviceV55, isWex, mode } = useShellRootContext();
  const { detailsState } = useStoreState();
  const { deviceInfo, deviceGroup } = detailsState;
  const [resFetchingImg, setResFetchingImg] = React.useState(true);

  const getDeviceImage = (images) => {
    return (
      <>
        {images.length > 0 && resFetchingImg ? (
          <PrinterImage
            data-testid="device-info-printer-image"
            src={fetchImage(images)}
            onError={({ currentTarget }) => {
              currentTarget.onerror = null; // prevents looping
              setResFetchingImg(false);
            }}
          />
        ) : (
          <IconPrinter data-testid="device-info-icon-printer" size={120} color={'gray4'} />
        )}
      </>
    );
  };

  return (
    <Wrapper data-testid="device-info">
      <UpperInfo isWex={isWex} mode={mode}>
        <DeviceImageWithTextSection>
          <ImageSection>{getDeviceImage(deviceInfo.images)}</ImageSection>
        </DeviceImageWithTextSection>

        <HostInfo>
          <DeviceName data-testid="details-accordion__device-name" id={'device-info__name'}>
            {deviceInfo.identity.friendlyName}
          </DeviceName>

          <HostLocation id={'device-info__location'}>{deviceInfo.identity.location}</HostLocation>
        </HostInfo>
      </UpperInfo>

      <LowerInfo>
        <DeviceStatus status={deviceInfo.status} />
        <DeviceStatusUpdated lastUpdatedAt={deviceInfo.status.lastUpdatedAt} />
        <DeviceConnectivity connectionState={deviceInfo.status.connectionState} />
        {ecpDeviceV55 && <DeviceAssessment solutionMetadata={deviceInfo.solutionMetadata} />}
        {props.isPolicyTab && <DeviceGroupStatus deviceGroup={deviceGroup} />}
      </LowerInfo>

      {!isWex && <DeviceStatusMessage status={deviceInfo.status} />}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  min-width: 320px;
  font-weight: 400;
  flex: 1;
`;

const DeviceImageWithTextSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const ImageSection = styled.div`
  padding-left: 12px;
  padding-right: 5px;
`;

const PrinterImage = styled.img`
  width: 170px;
`;

const UpperInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  margin: 25px 0;
  min-height: 145px;
  ${(props) => {
    if (props.isWex && props.mode == 'dark') return `color: ${tokens.color.white};`;
  }}
`;

const DeviceName = styled.div`
  font-size: 16px;
`;

const HostLocation = styled.div`
  font-size: 16px;
  max-height: 100px;
`;

const HostInfo = styled.div`
  display: flex;
  width: inherit;
  flex-direction: column;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 20px;
  min-height: 50px;

  ${DeviceName},
  ${HostLocation} {
    width: auto;
    color: #333333;
  }
`;

const LowerInfo = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 12px;
  flex-wrap: wrap;
`;
